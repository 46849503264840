<template>
    <div>
        <!-- <div>Pdf will be generated {{ progress }}%</div> -->
        <div>
            <vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="true"
                margin="[10,10,10,10]" filename="Customer Report" :paginate-elements-by-height="1400" :pdf-quality="2"
                pdf-format="A4" pdf-orientation="portrait" pdf-content-width="auto" :manual-pagination="true"
                @progress="onProgress($event)" @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">

                <section slot="pdf-content">
                    <section class="pdf-item">
                        <div class="col-md-12" v-if="viewno == 1">
                            <div class="row" style="margin-top:20px">
                                <div class="col-md-6">
                                    <h3 class="page-header">
                                        <i class="fa fa fa-users animated bounceInDown show-info"></i>
                                        Customer Report
                                    </h3>
                                </div>
                                <div class="col-md-6" v-if="!isprint">
                                    <div class="row" style="padding-top:18px;justify-content:flex-end;">
                                        <div class="col-md-2" style="margin-right:25px;">
                                            <button @click="refresh()" class="btn btn-primary"><i
                                                    class="fa fa-refresh"></i>
                                                Refresh</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="viewno == 1 && !isprint">
                            <div class="col-md-6">
                                <div class="info-box  bg-success-dark  text-white">
                                    <div class="info-icon bg-purple">
                                        <i class="fa fa fa-user fa-2x"></i>
                                    </div>
                                    <div class="info-details">
                                        <h4>Total Active Customer: <span class="badge bg-danger"
                                                style="font-size:medium;">{{ activecount }}</span></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info-box  bg-danger-dark  text-white">
                                    <div class="info-icon bg-purple">
                                        <i class="fa fa fa-user fa-2x"></i>
                                    </div>
                                    <div class="info-details">
                                        <h4>Total Defaulter Customer: <span class="badge bg-success"
                                                style="font-size:medium;">{{ defaultercount }}</span> </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="viewno == 1 && !isprint">
                            <div class="col-md-6">
                                <div class="info-box  bg-success-dark  text-white">
                                    <div class="info-icon bg-purple">
                                        <i class="fa fa fa-user fa-2x"></i>
                                    </div>
                                    <div class="info-details">
                                        <h4>Total Advisors: <span class="badge bg-danger" style="font-size:medium;">{{
                                                advisorcount }}</span></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info-box text-white" style="background-color:#fd7e14">
                                    <div class="info-icon bg-purple">
                                        <i class="fa fa fa-user fa-2x"></i>
                                    </div>
                                    <div class="info-details">
                                        <h4>Total De-Active Customer: <span class="badge bg-success"
                                                style="font-size:medium;">{{ deactive }}</span> </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <customer-search v-if="viewno == 1 && isprint == false" :displayfor="1" 
                            ref="childRef" :noofrec="noofrecord"></customer-search>
                        <div class="row" v-if="viewno == 1">
                            <div class="col-md-12 mt-20 flex-between-row" v-if="isprint == false">
                                <div class="col-md-6 flex-row">
                                    <!-- <div class="col-md-3">
                                        <strong>Show Rec.</strong>
                                    </div>
                                    <div class="col-md-3">
                                        <select class="form-control col-mb-1" v-model="noofrec" id="rec"
                                            @change="refresh()">
                                            <option value=10>10</option>
                                            <option value=25>25</option>
                                            <option value=50>50</option>
                                            <option value=100>100</option>
                                            <option value=200>200</option>
                                            <option value=500>500</option>
                                            <option value=1000>1000</option>
                                        </select>
                                    </div>
                                    <div class="">
                                        <button class="btn btn-primary" @click="exportExcelFile()">
                                            Excel
                                        </button>
                                    </div> -->
                                </div>
                                <div><button type="button" class="btn bg-success btn-xs text-white"
                                        @click="generateReport()">Print <i class='bx bx-printer'></i></button></div>
                                <div v-if="loginusercheck(126)" class="col-md-2"><button
                                        class="btn bg-success btn-xs text-white" @click="openmessage()">Message<img
                                            src="../../assets/messagebird.gif" height="40" width="60"></button></div>

                            </div>
                            <div class="col-md-12 table-responsive">
                                <div class="panel">
                                    <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                                        <table
                                            class="table users-table table-condensed table-hover table-bordered table-sm">
                                            <thead class="table-font" style="font-size:13px;">
                                                <tr>
                                                    <th class="visible-lg">#</th>
                                                    <th v-if="loginusercheck(124) && !isprint" style="width:63px;">
                                                        Verify <i class='bx bxs-badge-check'
                                                            style="color:#0299f7;font-size: 15px;"></i></th>
                                                    <th class="visible-lg">VSDIGI-ID</th>
                                                    <th class="visible-lg">Name</th>
                                                    <th>Contact</th>
                                                    <th>Address</th>
                                                    <th>Pincode</th>
                                                    <!-- <th>Landmark</th> -->
                                                    <th>Registation_date</th>
                                                    <th>Introducer Details</th>
                                                    <th v-if="!isprint">Entry By</th>
                                                    <th>Credit Limit</th>
                                                    <th v-if="loginusercheck(34) && !isprint">Edit</th>
                                                    <th>Ratings</th>
                                                    <th v-if="loginusercheck(119)">Advisor</th>
                                                    <th v-if="loginusercheck(36) && !isprint">Status</th>
                                                    <th v-if="loginusercheck(120) && !isprint">Set R.M.N</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-font" v-for="(item, index) in customers"
                                                v-bind:key="item.id">
                                                <!-- <tr v-for="(item,index) in customers" v-bind:key="item.id" :style="isdefaulter(item)"> -->
                                                <tr>
                                                    <td>{{ (currentpage - 1) * noofrecord + (index + 1) }}</td>
                                                    <td v-if="loginusercheck(124) && !isprint" class="text-center">
                                                        <input type="checkbox" :value="1" :checked="item.verified == 1"
                                                            @change="verifiedcustomer(item)">
                                                    </td>
                                                    <td class="visible-lg">
                                                        <a href="#" @click="detail(item)">{{ item.generatedid }}</a>
                                                        <span v-if="item.verified == 1"><i class='bx bxs-badge-check'
                                                                style="color:#0299f7;font-size: 15px;"></i></span>
                                                    </td>
                                                    <td>{{ item.name }}</td>
                                                    <td><display-mobiles :mobiles="item.mobiles"></display-mobiles></td>
                                                    <td><display-first-address
                                                            :addresses="item.addressess"></display-first-address></td>
                                                    <td v-if="item.addressess != null">{{ getpincode(item) }}</td>
                                                    <td>{{ moment(item.registation_date).format("DD-MM-YYYY") }}</td>
                                                    <td style="width:14%">
                                                        <template v-if="item.referraluser != null">
                                                            {{ item.referraluser.generatedid }}<br>
                                                            {{ item.referraluser.name }}
                                                            <display-mobiles
                                                                :mobiles="item.referraluser.mobiles"></display-mobiles>
                                                        </template>
                                                    </td>
                                                    <td v-if="!isprint">
                                                        <div v-if="item.enteredby != null && !isprint">
                                                            {{ item.enteredby.name }}<br>
                                                            {{ moment(item.created_at).format('DD-MM-YYYY') }}<br>
                                                            {{ moment(item.created_at).format('hh:mm:ss') }}
                                                        </div>
                                                    </td>
                                                    <td>{{ item.creditlimit }}</td>
                                                    <td style="padding:4px" v-if="loginusercheck(34) && !isprint">
                                                        <button type="button" class="badge btn-prime btn-xs"
                                                            @click="edit(item)" style="margin-bottom:5px;"><i
                                                                class="fa fa-edit">Edit</i></button>
                                                    </td>
                                                    <td style="width:8%" v-if="loginusercheck(121)">
                                                        <span v-if="isprint">{{ item.customer_type }}</span>
                                                        <select class="form-control" :style="ratingcolor(item)"
                                                            v-model="item.customer_type" @change="ratingmethod(item)"
                                                            v-if="!isprint">
                                                            <option value="A">A</option>
                                                            <option value="B">B</option>
                                                            <option value="C">C</option>
                                                            <option value="D">
                                                                <button type="button" class="btn btn-prime btn-xs"
                                                                    style="margin-bottom:5px;">
                                                                    <div class="font-size:10px;"
                                                                        v-if="item.isdefaulter == 0">
                                                                        D </div>
                                                                    <div class="font-size:10px;" v-else>D</div>
                                                                </button>
                                                            </option>
                                                        </select>

                                                    </td>
                                                    <td v-if="loginusercheck(119)">
                                                        <span>{{ item.isadvisor == 1 ? "Advisor" : "" }}</span>
                                                        <button type="button" class="btn btn-xs"
                                                            @click="makeadvisor(item)" v-if="!isprint">
                                                            <div class="btn-prime advisor b-r"
                                                                v-if="item.isadvisor == 0" style="padding:1px 5px">Make
                                                            </div>
                                                            <div class="b-r"
                                                                style="background-color:red;color:white;padding:1px 5px"
                                                                v-else>Remove </div>
                                                        </button>
                                                    </td>
                                                    <td v-if="loginusercheck(36) && !isprint">

                                                        <button type="button" class="btn btn-xs"
                                                            style="margin-bottom:5px;" @click="makedeactive(item)">
                                                            <div class="btn-success b-r advisor"
                                                                style="padding:1px 5px;" v-if="item.active == 0">
                                                                Deactive </div>
                                                            <div class="b-r"
                                                                style="background-color:red;color:white;padding:1px 5px"
                                                                v-else>Activate</div>
                                                        </button>
                                                    </td>
                                                    <td v-if="loginusercheck(120) && !isprint">
                                                        <button type="button" class="btn btn-xs"
                                                            @click="setmobilermn(item)">
                                                            <div class="btn-danger advisor b-r" style="padding:1px 5px">
                                                                R.M.N</div>
                                                            <!-- <div class="b-r" style="background-color:red;color:white;padding:1px 5px" v-else>Remove </div> -->
                                                        </button>
                                                    </td>
                                                </tr>
                                                <div v-if="page1(index)" class="html2pdf__page-break"
                                                    style="margin-bottom:20px;"></div>
                                            </tbody>
                                        </table>
                                        <div class="col-md-12" style="margin-top: 20px" v-if="!isprint">
                                            <nav aria-label="Page navigation example"
                                                style="text-align: right !important">
                                                <paginate style="margin: 0px !important; float: right"
                                                    :page-count="noofpages" :click-handler="clickCallback"
                                                    :prev-text="'&laquo;'" :next-text="'&raquo;'"
                                                    :container-class="'pagination'">
                                                </paginate>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <modal name="advisormodal" :width="500" :height="350" draggable=".window-header">
                                <div class="col-md-12 pb-10 text-center">
                                    <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">
                                        Welcome
                                        Advisor</h3>
                                </div>
                                <div class="col-md-12">
                                    <label for="remark">Remark</label>
                                </div>
                                <div class="col-md-12">
                                    <textarea id="remark" class="form-control " v-model="remark"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <button v-if="clickadvice == false"
                                        class="btn bg-success text-white btn-sm chkcondition"
                                        @click="savedavisorremark()">Save</button>
                                    <span v-if="clickadvice" class="spinner"></span>
                                </div>
                                <div class="col-md-12 row pt-10">
                                    <div class="col-md-6">
                                        <label for="dateofactivation">Date of Activation</label>
                                    </div>
                                    <div class="col-md-6">
                                        <input id="dateofactivation" type="date" class="form-control "
                                            v-model="advisordateofactive">
                                    </div>
                                </div>
                                <div class="col-md-12 row pt-10">
                                    <div class="col-md-6">
                                        <label for="Delivered">Delivered By</label>
                                    </div>
                                    <div class="col-md-6">
                                        <select v-model="advisorcreatorid" class="form-control " required>
                                            <option v-for="filter in employees" :key="filter.id" :value="filter.id">
                                                {{ filter.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-12 pt-20 pb-20" style="display:flex;flex-direction:row">
                                    <button class="btn bg-primary text-white btn-sm chkcondition"
                                        style="margin-right:10px;" @click="updateadvisor()">
                                        <span v-if="saveadvisor.isadvisor == 0">Make Advisor</span>
                                        <span v-else>Remove Advisor</span>
                                    </button>
                                    <button class="btn bg-danger text-white btn-sm chkcondition"
                                        @click="closeadvisormodal()">Close</button>
                                </div>
                            </modal>
                            <modal name="defaultermodal" :width="500" :height="350" draggable=".window-header">
                                <div class="col-md-12 pb-10 text-center">
                                    <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">
                                        Customer
                                        Ratings</h3>
                                </div>
                                <div class="col-md-12">
                                    <label for="remark">Remark</label>
                                </div>
                                <div class="col-md-12">
                                    <textarea id="remark" class="form-control " v-model="ratingsremark"></textarea>
                                </div>
                                <!-- <div class="col-md-12">
                                    <button v-if="clickadvice==false" class="btn bg-success text-white btn-sm chkcondition" @click="saveratingsremark()">Save</button>
                                        <span v-if="clickadvice" class="spinner"></span>
                                    </div> -->
                                <div class="col-md-12 row pt-10">
                                    <div class="col-md-6">
                                        <label for="dateofactivation">Date of Activity</label>
                                    </div>
                                    <div class="col-md-6">
                                        <input id="dateofactivation" type="date" class="form-control "
                                            v-model="ratingdateofactive">
                                    </div>
                                </div>
                                <div class="col-md-12 row pt-10">
                                    <div class="col-md-6">
                                        <label for="Delivered">Activity By</label>
                                    </div>
                                    <div class="col-md-6">
                                        <select v-model="ratingcreatorid" class="form-control " required>
                                            <option v-for="filter in employees" :key="filter.id" :value="filter.id">
                                                {{ filter.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-12 pt-20 pb-20" style="display:flex;flex-direction:row">
                                    <button class="btn bg-primary text-white btn-sm chkcondition"
                                        style="margin-right:10px;" @click="updateratings()">
                                        <span v-if="saveratings.isdefaulter == 0">Rate</span>
                                        <span v-else>Remove Defaulter</span>
                                    </button>
                                    <button class="btn bg-danger text-white btn-sm chkcondition"
                                        @click="closedefaultermodal()">Close</button>
                                </div>
                            </modal>
                            <set-r-m-n></set-r-m-n>

                        </div>
                        <customer-form v-if="viewno == 2 || viewno == 6"></customer-form>
                        <customer-detail v-if="viewno == 3"></customer-detail>
                        <modal name="greetCustomer" :width="500" :height="280" draggable=".window-header">
                            <div class="col-md-12 pb-10 text-center flex-row">
                                <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Greet
                                    Advisors</h3>
                            </div>
                            <div class="col-md-12">
                                <label for="remark">Message</label>
                            </div>
                            <div class="col-md-12">
                                <textarea id="remark" disabled class="form-control " v-model="message"></textarea>
                            </div>
                            <div class="col-md-12 flex-between-row pt-20">
                                <button class="btn bg-danger text-white btn-sm chkcondition" style="margin-right:10px;"
                                    @click="close()">Cancel</button>
                                <button v-if="!sendsmstrue" class="btn bg-primary text-white btn-sm chkcondition"
                                    style="margin-right:10px;" @click="sendsms()">Send Message</button>
                                <span v-if="sendsmstrue" class="spinner"></span>
                            </div>
                        </modal>
                    </section>
                </section>
            </vue-html2pdf>
        </div>
        <multiple-whats-app-templates v-if="viewno == 4"></multiple-whats-app-templates>
    </div>
</template>

<script>
import CustomerForm from './CustomerForm.vue';
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue';
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue';
import CustomerSearch from './CustomerSearch.vue';
import CustomerDetail from './CustomerDetail.vue';
import Paginate from "vuejs-paginate";
import moment from 'moment';
import VueHtml2pdf from 'vue-html2pdf'
import SetRMN from './SetRMN.vue';
import MultipleWhatsAppTemplates from '../whatsapp/MultipleWhatsAppTemplates.vue';

export default {
    mixins: [Constants],
    components: {
        CustomerForm, DisplayFirstAddress, DisplayMobiles, CustomerSearch, CustomerDetail, SetRMN,
        Paginate, VueHtml2pdf, MultipleWhatsAppTemplates
    },
    data() {
        return {
            activecount: 0,
            defaultercount: 0,
            noofrec: 10,
            moment: moment,
            remark: '',
            saveadvisor: [],
            clickadvice: false,
            advisorcreatorid: '',
            advisordateofactive: '',
            customer_type: '',
            ratingcreatorid: '',
            ratingdateofactive: '',
            ratingsremark: '',
            saveratings: [],
            deactive: 0,
            advisorcount: 0,
            message: 'Dear Team V.S Digiworld, Hamare channel se jud kar sales kar ke electronice industry me ujjwal bhavishya banaen aur har maheene Ghar baithe kamaen hazaro rupay jankari ke liye sampark karen 7565801550. Poonam Dubey',
            sendsmstrue: false,
            isprint: false,
            progress: 0
        }
    },
    computed: {
        ...mapGetters([
            'viewno', 'customers', 'loggedinuser', 'customerscount', 'employees','currentpage','noofrecord'
        ]),
        noofpages() {
            let result = Math.ceil(parseInt(this.customerscount) / parseInt(this.noofrecord))
            return result
        }
    },
    mounted() {
        // this.$store.dispatch('fetchcustomerscount')
        this.refresh();
    },

    methods: {
        verifiedcustomer(item) {
            let parm = { column: 'verified', id: item.id, value: item.verified == 1 ? 0 : 1 };
            this.$http.post('api/user/updatefield', parm)
                .then((response) => {
                    this.$store.commit('updateappendcustomers', response.data);
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        clickCallback(currentpage) {
            // this.currentpage = currentpage
            console.log("current page",currentpage)
            this.$store.commit("assigncurrentpage", currentpage);
            this.$refs.childRef.search()
            // this.refresh();
        },
        getpincode(item) {
            if (item.addressess != null) {
                // console.log(item.addressess[0]);
                return item?.addressess[0]?.zipcode
            } else {
                return ''
            }
        },
        updateratings() {
            this.$store.commit('assignloadingstatus', 1)
            console.log(this.saveratings.customer_type);
            let param = {
                id: this.saveratings.id, customer_type: this.saveratings.customer_type, ratingcreatorid: this.ratingcreatorid,
                ratingdateofactive: this.ratingdateofactive, ratingremark: this.ratingsremark
            }
            this.$http.post('api/createupdate/ratings', param)
                .then((response) => this.processratingResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processratingResponse() {
            this.$store.commit("assignloadingstatus", 0)
            this.$modal.hide('defaultermodal')
        },
        ratingcolor(item) {
            if (item.customer_type == 'A' || item.customer_type == 'B') {
                return "background:green;color:white;"
            } else if (item.customer_type == 'C') {
                return "background:orange;color:white;"
            } else if (item.customer_type == 'D' || item.isdefaulter == 1) {
                return "background:red;color:white;"
            }
        },

        ratingmethod(item) {
            this.$modal.show('defaultermodal')
            this.saveratings = item
        },
        openmessage() {
            this.$modal.show('greetCustomer')
        },
        close() {
            this.$modal.hide('greetCustomer')
        },
        makeadvisor(item) {
            this.$modal.show('advisormodal')
            this.saveadvisor = item
            if (item.advisorremark != null) {
                this.remark = item.advisorremark
                this.advisordateofactive = item.advisordateofactive
                this.advisorcreatorid = item.advisorcreatorid
            } else {
                this.remark = ''
                this.advisordateofactive = ''
                this.advisorcreatorid = ''
            }
        },
        setmobilermn(item) {
            if (item.mobiles != null && item.mobiles.length > 0) {
                this.$modal.show('setrmn')
                this.$store.commit('assignsetmobileno', item)
            } else {
                alert("No Mobile number Found to set RMN")
            }
        },
        savedavisorremark() {
            this.clickadvice = true
            let parm = { column: 'advisorremark', id: this.saveadvisor.id, value: this.remark };
            this.$http.post('api/user/updatefield', parm)
                .then((response) => {
                    this.$store.commit('updateappendcustomers', response.data);
                    this.clickadvice = false
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        makedeactive(item) {   // Active and Deactive Customer
            let status = item.active == 0 ? "Deactivate" : "activate";
            if (confirm("Do you really want to " + status) == true) {
                let parm = { column: 'active', id: item.id, value: item.active == 0 ? 1 : 0 };
                this.$http.post('api/user/updatefield', parm)
                    .then((response) => {
                        this.$store.commit('updateappendcustomers', response.data);
                        this.clickadvice = false
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            }
        },
        updateadvisor() {
            this.$store.commit('assignloadingstatus', 1)
            if (this.saveadvisor.isadvisor == 1) {
                let parm = {
                    advisorremark: this.remark, id: this.saveadvisor.id, advisorcreatorid: this.advisorcreatorid,
                    advisordateofactive: this.advisordateofactive, isadvisor: 0
                };
                this.$http.post('api/user/updateadvisorfield', parm)
                    .then((response) => {
                        this.$store.commit('updateappendcustomers', response.data);
                        this.$store.commit('assignloadingstatus', 0)
                        this.$modal.hide('advisormodal')
                        this.refresh();
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            } else {
                this.$store.commit('assignloadingstatus', 1)
                let parm = {
                    advisorremark: this.remark, id: this.saveadvisor.id, advisorcreatorid: this.advisorcreatorid,
                    advisordateofactive: this.advisordateofactive, isadvisor: 1
                };
                this.$http.post('api/user/updateadvisorfield', parm)
                    .then((response) => {
                        this.$store.commit('updateappendcustomers', response.data);
                        this.$store.commit('assignloadingstatus', 0)
                        this.$modal.hide('advisormodal')
                        this.refresh();
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            }
        },

        edit(item) {
            this.$store.commit('assignedititem', item)
            this.$store.commit('assignviewno', 2)
        },
        detail(item) {
            if (this.loginusercheck(35)) {
                this.$store.commit('assignedititem', item)
                this.$store.commit('assignviewno', 3)
            } else {
                alert("You Don't Have Right To Access")
            }

        },
        refresh() {
            this.$children.searchdetails = true
            let param = { noofrec: this.noofrecord, currentpage: this.currentpage, }
            this.$store.dispatch('fetchcustomers', param)
            this.$store.dispatch('fetchemployees')

            // this.$store.dispatch('fetchadvisors')
            this.$store.dispatch('fetchmaster')
            let param1 = { active: 0, iscustomer: 1, isregister: 0 }
            this.$http.post("api/users/fetch/count", param1)
                .then((response) => this.ProcessResponseCount(response.data))
                .catch((err) => {
                    console.log(err)
                });
            let param2 = { isdefaulter: 0, iscustomer: 1, isregister: 0 }
            this.$http.post("api/users/fetch/count", param2)
                .then((response) => this.ProcessCountdefaulter(response.data))
                .catch((err) => {
                    console.log(err)
                });
            let param3 = { active: 1, isadvisor: 0 }
            this.$http.post("api/users/fetch/count", param3)
                .then((response) => this.ProcessCountActive(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.$http.post('api/users/totaladvisor/count')
                .then((response) => this.processadvisortotalCountResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processadvisortotalCountResponse(data) {
            this.advisorcount = data
        },
        ProcessCountActive(data) {
            this.deactive = data
        },
        ProcessCountdefaulter(data) {
            this.defaultercount = data
        },
        ProcessResponseCount(data) {
            this.activecount = data
            this.$store.commit('assigncustomerscount',data)
        },
        back() {
            this.$store.commit('assignviewno', 1)
        },
        closeadvisormodal() {
            this.$modal.hide('advisormodal')
            this.remark = ''
            this.advisordateofactive = ''
            this.advisorcreatorid = ''
        },
        closedefaultermodal() {
            this.$modal.hide('defaultermodal')
            this.remark = ''
            this.advisordateofactive = ''
            this.advisorcreatorid = ''
        },
        sendsms() {
            this.sendsmstrue = true
            let param = { message: this.message }
            this.$http.post('api/customers/send/sms', param)
                .then((response) => this.processsms(response.data))
                .catch((err) => {
                    console.log(err)
                });

        },
        processsms(data) {
            if (data != null) {
                this.sendsmstrue = false
                alert(data.message)
                this.$modal.hide('greetCustomer')
            }
        },
        errorShow() {
            this.$store.commit('assignloadingstatus', 0)
            alert("Something went wrong, please try after sometime.")
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded() {
            this.$store.commit('assignloadingstatus', 0)
            this.isprint = false
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()
        },
        generateReport() {
            this.$store.commit('assignloadingstatus', 1)
            this.isprint = true
            this.$refs.html2Pdf.generatePdf()
        },
        page1(index) {
            // (index!=0 && index==6 && index!=8) || (index!=0 && index!=6 && index%6==0)
            if (index != 0 && index == 8) {
                return true
            }
            // if(index!=0 &&  index!=6 && index%6==0){
            if ((index - 8) % 9 == 0) {
                return true
            }
            return false
        },
        exportExcelFile() {
            this.$refs.childRef.exportExcel();
        }
    }
}
</script>
<style>
.btn-primary:hover {
    text-decoration: none !important;
    background-color: #3a476b !important;
}

.b-r {
    border-radius: 5px;
}
</style>
